export const decryptApiList = [
    '/api/goods/getGoodsList',
    '/api/goods/getGoodsListByEs',
    '/api/user/userBargain',
    '/api/goods/goodsInfo',
    '/api/user/userRealInfo',
    '/api/user/getUserBargainGoodsList',
    '/api/order/orderDetail',
    '/api/comm/queryCertificateInfo',
    '/api/order/createPartialPaymentRecordFn',
    '/api/order/getPartialPaymentRecordDetail',
    '/api/comm/getKeyWordGoodsList'
]

export const encryptApiList = [
    '/api/comm/sendSmsCode',
    // '/api/comm/certificateInfo',
    '/api/user/updateUserRealInfo',
    '/api/pay/recPay',
    '/api/order/bargainOrder',
    '/api/order/payBargainOrder',
    '/api/order/placeOrder',
    '/api/order/createPartialPaymentRecordFn',
    '/api/order/updataOrderInsure',
    '/api/pay/addInsureRecPayFn',
]


