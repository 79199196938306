import {
  Component,
  Vue,
  Emit,
  Prop
} from "vue-property-decorator";
import Cookie from "~/utils/cookie/cookie";
import moment from "moment";
import axios from "~/plugins/axios/index";
import {
  visitorLogin,
  updateBargainOrderVisitorId
} from "../api/chat";

import configRequest from "./axios/config";

moment.locale("zh-cn");
import {
  Button,
  Input,
  InputNumber,
  Select,
  Upload,
  Notification,
  Message,
  MessageBox,
} from "element-ui";

Vue.mixin({
  data() {
    return {
      mobileRegular: /^[1][0-9]{10}$/,
      qqRegular: /^[1-9]\d{4,11}$/,
      idCardRegular: /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/,
      emailRegular: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
      priceRegular: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
      integerRegular: /^-?[1-9]\d*$/, //整数
    };
  },
  mounted() {
    if (window.location.href.includes("?")) {
      const addressUrl = window.location.href.split("?")[1]; //获取url中"?"符后的字串

      let arr;
      let obj = {};

      arr = addressUrl.split("&");

      console.log(arr, "----");

      if (arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          let arrNew = arr[i].split("=");
          obj[arrNew[0]] = arrNew[1];
        }
      } else {
        let arrNew = addressUrl.split("=");
        obj[arrNew[0]] = arrNew[1];
      }

      console.log(obj, "++++obj");

      if (obj["bd_vid"]) {
        console.log("拿到了bd_vid", obj["bd_vid"]);
        const params = JSON.stringify(obj["bd_vid"]);
        window.sessionStorage.setItem("bd_vid", params);
      }
    }
  },
  methods: {
    // //打开链接:url:路由，type:打开方式， isLogin:是否需要登录
    openUrl(oldUrl, type = "_blank", isLogin = false) {
      let url = oldUrl.replace(/www\.hk1686\.com/g, "www.hk1686.vip")
      if (
        url &&
        url != "#" &&
        (url.indexOf("http://") > -1 || url.indexOf("https://") > -1)
      ) {
        if (type == "_blank" || type == "_self") {
          window.open(url, type);
        } else {
          window.open(url, "_blank");
        }
      } else if (url && url != "#") {
        if (isLogin && !this.getCookie("token")) {
          this.eventMsgTost("请登录", "warning");
          this.$router.push("/login");
        } else {
          this.$router.push(url);
        }
      } else {
        console.log("链接异常");
      }
    },
    isMyGoods(userId) {
      return userId == this.getCookie("userId");
    },
    // 在线咨询企点客服
    openOnlineService(url, sn = "999", dec = "首页咨询") {
      if (url && url.includes("http") && url.includes("53kf")) {
        // 判断是53客服链接的话 那就特殊处理
        let userId = this.getCookie("userId") ?
          this.getCookie("userId") :
          "999";
        let tempUrl = `${url}&u_cust_id=${userId}&u_cust_name=${
          sn == "999" ? userId : sn
        }-${dec}`;
        this.openUrl(tempUrl);
      } else if (url && url.includes("http")) {
        // 普通链接直接打开
        this.openUrl(url);
      } else {
        // qq号 拼接打开
        window.open(
          `http://wpa.qq.com/msgrd?v=3&uin=${url}&site=qq&menu=yes`,
          "_blank"
        );
      }
      // window.open(url,'_blank','width=1000,height=700,top=100px,left=400px')
    },

    // 买号客服(小章)
    // openBuyService(ent_id,sn='999',dec='首页咨询'){
    //   window.open(`http://169.254.221.13:8081/chatIndex?ent_id=`+ent_id, '_blank')
    // },
    // 卖号客服(小章)
    // openSellService(ent_id,sn='999',dec='首页咨询'){
    //   window.open(`http://169.254.221.13:8081/chatIndex?ent_id=`+ent_id, '_blank')
    // },

    //     openToService(customer_id, visitor_id, visitor_name, extra,room) {
    //       if (process.env.NODE_ENV === "development") {
    // <<<<<<< HEAD
    //         var baseURL = "http://169.254.29.244:8888/kefu-admin#/"
    // =======
    //         var baseURL = "http://169.254.29.244:8889/kefu-admin#/"
    // >>>>>>> master
    //         // var  baseURL = "http://localhost:8008"
    //       } else {
    //         baseURL = "https://www.hk1686.cn/"
    //       }
    // <<<<<<< HEAD
    //       //在进入具体的聊天请求之前，先获取分批的房间id
    // if(!room) room = room_id;
    //         window.open(baseURL + "chatIndex?customer_id=" + customer_id + "&visitor_id=" + visitor_id + "&visitor_name=" + visitor_name +"&is_room="+room+ "&goodSn="+goodSn, "鸿凯客服页面", "height=1200, width=1550, top=200, left=500, toolbar='no', z-look=yes");
    // =======
    //       if (extra) {
    //         window.open(baseURL + "chatIndex?customer_id=" + customer_id + "&visitor_id=" + visitor_id + "&visitor_name=" + visitor_name + "&extra=" + extra, "鸿凯客服页面", "height=900, width=1200, top=200, left=700, toolbar='no', z-look=yes");
    //       } else {
    //         if(room){
    //           window.open(baseURL + "chatIndex?customer_id=" + customer_id + "&visitor_id=" + visitor_id + "&visitor_name=" + visitor_name +"&is_room="+room, "鸿凯客服页面", "height=900, width=1200, top=200, left=700, toolbar='no', z-look=yes");
    //         }else{
    //           window.open(baseURL + "chatIndex?customer_id=" + customer_id + "&visitor_id=" + visitor_id + "&visitor_name=" + visitor_name, "鸿凯客服页面", "height=900, width=1200, top=200, left=700, toolbar='no', z-look=yes");
    //         }
    //       }
    // >>>>>>> master
    //     },

    async openToService(
      customer_id,
      params,
      extra,
      room,
      goodSn,
      feedId,
      sendMessage,
      orderSn,
      needSend,
      messageCount
    ) {

      console.log(customer_id, "获取到的参数")
      console.log(params, "获取到的参数")
      console.log(extra, "获取到的参数")
      console.log(room, "获取到的参数")
      console.log(goodSn, "获取到的参数")
      console.log(feedId, "获取到的参数")
      console.log(sendMessage, "获取到的参数")
      console.log(orderSn, "获取到的参数")
      console.log(needSend, "获取到的参数")
      console.log(messageCount, "获取到的参数")
      console.log("数据", params);
      let visitorName = this.getCookie("phoneNum");
      let userId = this.getCookie("userId");
      let baseURL = `${configRequest.customerUrl}/kefu-admin#/`
      if (!room) {
        let res = await visitorLogin({
          visitor_id: userId,
          visitor_name: visitorName,
          visit_num: 1,
          customer_id: customer_id,

          goodsSn: params && params.goodsSn ? params.goodsSn : "",
          goodsCode: params && params.goodsCode ? params.goodsCode : "",
          customerType: params && params.customerType ? params.customerType : 1,
          price: params && params.price ? params.price : "",
          gameName: params && params.gameName ? params.gameName : "",
          gameId: params && params.gameId ? params.gameId : "",
        });

        if (res.code == 200) {
          room = res.result.id; //有房间号就跳转没有房间号就不跳转
        }
      }
      if (sendMessage && sendMessage === "bargain1") {
        sendMessage = "您好,我已对该砍价商品支付了尾款,请及时处理!";
      }

      if (sendMessage && sendMessage === "bargain2") {
        sendMessage = "您好,我已对该砍价商品支付了定金,请及时处理!";
        if (orderSn) this.handleUpdateBargainOrderVisitorId(room, orderSn);
      }

      if (sendMessage && sendMessage === "bargain3") {
        sendMessage = "您好,我已对该砍价商品进行了分批支付尾款,请及时处理!";
      }

      window.open(
        baseURL +
        "chatIndex?customer_id=" +
        customer_id +
        "&visitor_id=" +
        userId +
        "&visitor_name=" +
        visitorName +
        "&is_room=" +
        room +
        "&goodSn=" +
        goodSn +
        "&feedId=" +
        feedId +
        "&sendMessage=" +
        sendMessage +

        "&needSend=" +
        needSend +
        "&messageCount=" +
        messageCount +
        "&tkString=" +
        this.getCookie("token"),
        "鸿凯客服页面",
        "height=900, width=1600, top=200, left=700, toolbar='no', z-look=yes"
      );
    },
    async handleUpdateBargainOrderVisitorId(roomId, orderSn) {
      let params = {
        visitor_id: roomId,
        order_sn: orderSn,
      };
      let res = await updateBargainOrderVisitorId(params);
    },
    //打开qq链接
    openQQLink(qq) {
      console.log(qq);
      if (qq && (qq.indexOf("http://") > -1 || qq.indexOf("https://") > -1)) {
        window.open(qq);
      } else {
        window.open(`http://wpa.qq.com/msgrd?v=3&uin=${qq}&site=qq&menu=yes`);
      }
    },
    // 当前页面跳转到指定位置
    goScrollTo(value) {
      if (value >= 0) {
        window.scrollTo(0, value);
      }
    },

    // 图片格式转换
    changeImgFormat(img) {
      // console.log(img,img.indexOf('/')==0,img.indexOf('.')>-1,'=====img===哈哈哈哈哈哈==')
      if (img) {
        if (img.indexOf("http://") > -1 || img.indexOf("https://") > -1) {
          return img;
        } else if (img.indexOf("/") == 0) {
          return `https://oss1.hk1686.com${img}`;
        } else if (img.indexOf(".") > -1) {
          return `https://oss1.hk1686.com/${img}`;
        } else {
          return `https://oss1.hk1686.com/${img}`;
        }
      } else {
        return "";
      }
    },

    //时间格式化，type：YYYY-MM-DD HH:mm:ss
    timeFormatUser(time, type) {
      return moment(time).format(type);
    },
    // 金额操作，小数取整加1，整数不操作
    changePrice(price, addPrice = 0, goodsSn = "") {
      if (goodsSn && process.browser) {
        let data1 = [];
        if (!!window.localStorage.bargainList) {
          data1 = JSON.parse(String(window.localStorage.bargainList));
        }
        if (!!data1 || data1.length > 0) {
          let flag = data1.findIndex((v) => v.goodsSn == goodsSn);
          if (flag > -1) {
            price = data1[flag].sellrBargaPrice;
            return price.toFixed(2);
          }
        }
      }
      let priceFlag = Number(price) * (1 + Number(addPrice));
      if (
        (price && !String(priceFlag).includes(".")) ||
        (priceFlag && String(priceFlag).includes(".00"))
      ) {
        return parseInt(priceFlag).toFixed(2);
      } else {
        return parseInt(priceFlag + 1).toFixed(2);
      }
    },
    //获取游戏大区服务器
    getDetails(v) {
      let flag = {};
      if (v) {
        flag = eval("(" + v + ")");
        return `${flag.gameName}-${flag.areaName}-${flag.serverName}`;
      } else {
        return "";
      }
    },
    // 返回游戏单位str：传入title
    coinUnitType(str) {
      let coinUnit = [
        //游戏币单位
        "万金币",
        "金币",
        "万基纳",
        "基纳",
        "万钻石",
        "钻石",
        "万宝票",
        "宝票",
      ];
      if (typeof str == "string") {
        return coinUnit.find((v) => str.includes(v));
      }
    },
    //显示跨区(商品标题后显示跨区等信息)
    isShowCross(item) {
      if (item.crossId && item.crossId == "1") {
        return item.crossName.replace("DNF", "");
      } else if (item.crossId) {
        return item.crossName;
      }
      return "";
    },
    //返回金币交易方式的名字deliver，v:deliver,item:商品详情
    getGoldDeliver(v, item) {
      if (item) {
        if (item.gameId == 17 || item.game == 17) {
          return "【" + item.camp + "】";
        } else {
          if (v == 1) {
            return "【邮寄交易】";
          } else if (v == 2) {
            return "【拍卖交易】";
          } else if (v == 3) {
            return "【当面交易】";
          }
        }
      } else {
        return "";
      }
    },
    // 一元=？？？金币
    oneRMBToTenThousand(item) {
      let flag = this.coinUnitType(item.title);
      return Number(item.number / item.price) ?
        `1元=${Number(item.number / item.price).toFixed(2)}${flag}` :
        `1元="0.00"${flag}`;
    },
    // 判断商品是否是分销商自己的商品
    judgeDstOwnGoods(distributor_id) {
      if (
        !!this.$store.state.web.distributorsObject.distId &&
        !!distributor_id
      ) {
        return (
          this.$store.state.web.distributorsObject.distId ==
          this.orderInfo_.second_goods.distributor_id
        );
      } else {
        return false;
      }
    },
    // 检查表单是否为空，如果是手机号，QQ然后做特殊判断
    checkForm(form) {
      console.log("===form====", form, "=====form========");
      for (const v of form) {
        if (!v.value || (!v.value && v.tip)) {
          this.eventMsgTost(`${v.tip}`, "warning");
          return false;
        } else if (v.key == "phoneNum") {
          if (!this.mobileRegular.test(v.value)) {
            this.eventMsgTost(`手机号格式错误`, "warning");
            return false;
          }
        } else if (v.key == "qqNum") {
          // if (!this.qqRegular.test(v.value)){
          //   this.eventMsgTost(`QQ号格式错误`,"warning");
          //   return false
          // }
          if (!v.value) {
            this.eventMsgTost(`QQ号格式错误`, "warning");
            return false;
          }
        } else if (v.key == "desiCode") {
          if (!v.value) {
            this.eventMsgTost(`请输入暗号`, "warning");
            return false;
          } else if (v.value.length < 6 || v.value.length > 18) {
            this.eventMsgTost(`暗号长度为6-18位`, "warning");
            return false;
          }
        }
      }
      return true;
    },
    // 复制
    copy(v) {
      const input = document.createElement("input");
      input.setAttribute("readonly", "readonly");
      input.setAttribute("value", v);
      document.body.appendChild(input);
      input.setSelectionRange(0, 9999);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        console.log("复制成功");
        this.eventMsgTost(
          "复制成功", //warning  error
          "success"
        );
      }
      document.body.removeChild(input);
    },

    // 打开商品列表页
    openGoodsList(params, flag = "_blank") {
      console.log(params, "====params====1111");
      if (params.goodsTypeId && params.gameId) {
        this.openUrl(
          `/wares/?gt=${params.goodsTypeId}&gid=${params.gameId}`,
          flag
        );
      } else if (params.goodsTypeStr) {
        let goodsTypeArray = params.goodsTypeStr.split(",");
        if (goodsTypeArray[0]) {
          params["goodsTypeId"] = goodsTypeArray[0];
          console.log(goodsTypeArray, params);
          this.openUrl(
            `/wares/?gt=${params.goodsTypeId}&gid=${params.gameId}`,
            flag
          );
        }
      } else if (params.gameId && !params.goodsTypeId) {
        params["goodsTypeId"] = 1;
        this.openUrl(
          `/wares/?gt=${params.goodsTypeId}&gid=${params.gameId}`,
          flag
        );
      }
    },
    // 打开商品详情页：{sn:'',goodsTypeId:''}
    openGoodsDetail(params, flag = "_blank") {
      console.log(params, "====params====");
      if (
        params.goodsTypeId &&
        (params.goodsTypeId === "2" || params.goodsTypeId === "22")
      ) {
        //金币

        window.open(`/JDetail/${params.goodsSn}`, flag);
      } else {
        window.open(`/detail/${params.goodsSn}`, flag);
      }
    },
    // 商品列表页链接
    getGoodsListUrl(params) {
      console.log(params, "====params====");
      if (params.gameId && params.gameId == 18 && !params.goodsTypeId) {
        //资源群
        params["goodsTypeId"] = 8;
        return `/wares/?gid=18&gt=8`;
      } else {
        if (!params["goodsTypeId"]) {
          params["goodsTypeId"] = 1;
        }
        return `/wares/?gt=${params.goodsTypeId}&gid=${params.gameId}`;
      }
    },
    // 商品详情页链接
    getGoodsDetailUrl(params) {
      console.log(params, "====params====");
      if (
        params.goodsTypeId &&
        (params.goodsTypeId == "2" || params.goodsTypeId == "22")
      ) {
        //金币
        return `/JDetail/${params.goodsSn}`;
      } else {
        return `/detail/${params.goodsSn}`;
      }
    },
    //电脑端页面转到特定的手机端页面
    changeToMobileUrl(params) {
      if (params.type == "goods") {
        return `${location.origin}/h/goodsDetail/${this.$route.params.sn}`;
      }
    },
    //获取手机端首页路由
    changeToMobileHome() {
      return `${location.origin}/h/`;
    },
    //返回商品类型的图片(v:deliverId,type:goodsTypeId)
    getDeliverImg(v, type = 2) {
      // deliver
      if (type == 6) {
        return require("static/image/goodsType/zhuangbei.png");
      } else if (type == 7) {
        return require("static/image/goodsType/dailian.png");
      } else if (type == 8 || type == 10) {
        return require("static/image/goodsType/qun.png");
      } else if (type == 11) {
        return require("static/image/goodsType/pei.png");
      } else if (type == 3) {
        return require("static/image/goodsType/coin.png");
      } else if (type == 21 || type == 25 || type == 26 || type == 27) {
        return require("static/image/goodsType/dan.png");
      } else if (type == 24) {
        return require("static/image/goodsType/zu.png");
      } else if (type == 2) {
        if (v == 1) {
          return require("static/image/goodsType/you.png");
        } else if (v == 2) {
          return require("static/image/goodsType/pai.png");
        } else if (v == 3) {
          return require("static/image/goodsType/mian.png");
        } else {
          return require("static/image/goodsType/coin.png");
        }
      } else {
        return require("static/image/goodsType/zhang.png");
      }
    },
    getCookie(dataStr = "token") {
      return Cookie.getCookiesInClient(dataStr);
    },
    removeCookie(key = "detailId") {
      return Cookie.setCookiesInClient(key, "", -1);
    },
    setCookie(key = "detailId", dataStr) {
      return Cookie.setCookiesInClient(key, dataStr);
    },
    //设置session，放在session，关闭浏览器时，session会自动清除
    setSession(key, value) {
      let session = window.sessionStorage.getItem("pullOrNot");
      let sessionObject = {};
      if (session) {
        sessionObject = eval("(" + session + ")");
      }
      console.log(session, sessionObject, "====sessionObject=====");
      sessionObject[key] = value;
      let sessionString = JSON.stringify(sessionObject);
      console.log(sessionString, "====sessionString=====");
      window.sessionStorage.setItem("pullOrNot", sessionString);
    },
    //获取session
    getSession(key) {
      let session = window.sessionStorage.getItem("pullOrNot");
      let sessionObject = {};
      if (session) {
        sessionObject = eval("(" + session + ")");
      }
      console.log(session, sessionObject, "====sessionObject=====");
      if (sessionObject[key]) {
        return sessionObject[key];
      } else {
        return sessionObject[key];
      }
    },
    logout() {
      this.removeCookie("token");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("token");
      this.removeCookie("username");
      this.removeCookie("userId");
      this.removeCookie("actor");
      this.removeCookie("nickName");
      this.removeCookie("phoneNum");
      window.open("/", "_self");

      // 退出时清除搜索的关键词
      window.localStorage.removeItem('kw');

      this.eventNotifyTost("成功", "退出登录成功", "success");
    },
    //全局 异常抛出
    errorToastFn(data) {
      if (data.code === 0) {
        return true;
      } else {
        if (data.msg) {
          if (
            String(data.msg).indexOf("登录已过期") > -1 ||
            String(data.msg).indexOf("token") > -1
          ) {
            this.removeCookie("token");
            this.removeCookie("username");
            this.removeCookie("actor");
            this.removeCookie("nickName");
            this.removeCookie("userId");
            this.removeCookie("phoneNum");
            window.open("/login", "_self");
            this.eventMsgTost("登录已过期，请重新登录", "warning");
            return false;
          } else {
            if (data.msg && JSON.stringify(data.msg).indexOf("tcp") > -1) {
              this.eventMsgTost("网站正在更新，请稍候...", "warning");
              return false;
            } else {
              this.eventMsgTost(data.msg, "warning");
              return false;
            }
          }
        } else {
          return false;
        }
      }
    },
    //事件提示弹框公用方法
    eventMsgTost(msg = "", type = "warning") {
      console.log(msg);
      this.$message({
        message: msg,
        type: type,
        duration: 3000,
      });
    },
    // 事件提示弹框公用方法
    eventNotifyTost(title = "提示", msg = "", type = "warning") {
      console.log(msg);
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 3000,
      });
    },
    // 暂未开放提示
    notOpenNotice(msg = "暂未开放，敬请期待！", type = "info") {
      this.$message({
        message: msg,
        type: type,
        duration: 3000,
      });
    },
  },
});
